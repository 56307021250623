import { useAuth } from '@lib/auth/AuthContext';
import { getAccessToken } from '@lib/auth/token';
import { getEnv } from '@lib/env/getEnv';
import { captureError } from '@lib/fetch/errors';
import { AugmentedGetServerSideProps } from '@lib/fetch/types';
import { makeTranslations } from '@lib/localization/i18n';
import { AvailableLocales } from '@lib/route/types';
import { TENANT_CONFIG } from '@lib/tenants/config/constants';
import { NextPage } from 'next';
import { SSRConfig, useTranslation } from 'next-i18next';

import { api } from '@api/index';
import { HomePageDoc, HomePageRo } from '@api/prismic/static-pages/types';
import { PageLayout } from '@templates/common/layout/page-layout/PageLayout';
import { ActivateSimCardsAlert } from '@templates/home/home-auth/ActivateSimCardsAlert';
import { HomeAuth } from '@templates/home/home-auth/HomeAuth';
import { HomeGuest } from '@templates/home/HomeGuest';

interface Props extends SSRConfig {
  homeSlices: HomePageDoc['data'] | null;
  locale: AvailableLocales;
}

const HomePage: NextPage<Props> = ({ homeSlices }) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <PageLayout
        tabTitle={t('home:auth.tabTitle')}
        alert={<ActivateSimCardsAlert />}
      >
        <HomeAuth />
      </PageLayout>
    );
  }

  return (
    <PageLayout tabTitle={t('home:guest.tabTitle')}>
      <HomeGuest data={homeSlices} t={t} />
    </PageLayout>
  );
};

export const getServerSideProps: AugmentedGetServerSideProps<Props> = async (
  ctx,
) => {
  const env = getEnv();
  const { tenantName, cookies } = ctx.req;
  const accessToken = getAccessToken(tenantName, cookies);
  const { translations, locale } = await makeTranslations(ctx.locale, [
    'home',
    'profile',
  ]);
  const previewRef = ctx.previewData?.ref ?? null;
  let res: HomePageRo;

  if (!accessToken) {
    const externalHomeUrl =
      TENANT_CONFIG[ctx.req.tenantName].config.setup.externalHomePage?.[
        env.client.appEnv
      ];

    if (externalHomeUrl) {
      ctx.res.setHeader('Location', externalHomeUrl);
      ctx.res.statusCode = 301;
    } else {
      try {
        res = await api.prismic.staticPages.getHomePage(
          TENANT_CONFIG[ctx.req.tenantName],
          locale,
        );
      } catch (err) {
        captureError(`CMS error while fetching homepage`, err);
      }
    }
  }

  return {
    props: {
      ...translations,
      homeSlices: res?.data ?? null,
      locale,
      previewRef,
    },
  };
};

export default HomePage;
