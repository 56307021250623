import { Box, Divider, Fade, Grid, Typography } from '@mui/material';
import { Button, Button as LoadingButton } from '@ui/buttons/Button';

import { CloseModalIcon } from './CloseModalIcon';
import { PropsWithChildren } from 'react';

interface Props {
  titleLabel?: string;
  submitLabel?: string;
  cancelLabel?: string;
  minWidth?: string;
  isLoading?: boolean;
  onSubmit?(data?: unknown): void;
  onCancel(): void;
  isSubmitDisabled?: boolean;
  showCloseModalIcon?: boolean;
}

export const ContentModalLayout: React.FC<PropsWithChildren<Props>> = ({
  titleLabel,
  submitLabel,
  cancelLabel,
  isLoading,
  onSubmit,
  onCancel,
  children,
  minWidth = '40vw',
  isSubmitDisabled = false,
  showCloseModalIcon = false,
}) => {
  return (
    <Fade in>
      <Box
        maxWidth="80vw"
        minWidth={minWidth}
        display="flex"
        flexDirection="column"
        padding={[2, 4, 3]}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          borderRadius: '16px',
          position: 'relative',
        })}
      >
        {titleLabel && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={4}
            >
              <Typography id="modal-title" fontSize="20px" fontWeight={600}>
                {titleLabel}
              </Typography>
              {showCloseModalIcon && <CloseModalIcon onCancel={onCancel} />}
            </Box>
            <Divider
              sx={{ position: 'absolute', top: '70px', left: 0, right: 0 }}
            />
          </>
        )}
        <Grid
          container
          spacing={2}
          justifyContent={!submitLabel ? 'flex-end' : 'flex-start'}
        >
          <Grid item xs={12}>
            {children}
          </Grid>
          {cancelLabel && (
            <Grid item md={submitLabel ? 6 : 12} xs={12}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={onCancel}
                name="cancel"
              >
                {cancelLabel}
              </Button>
            </Grid>
          )}
          {submitLabel && (
            <Grid item md xs>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={isLoading}
                onClick={onSubmit}
                name="save"
                disabled={isSubmitDisabled}
              >
                {submitLabel}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Fade>
  );
};
