import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@lib/route/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { LinkButton } from '@ui/buttons/LinkButton';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { SubscriptionAddonsContent } from './SubscriptionAddonsContent';
import { useManageSubscriptions } from '../common/useManageSubscriptions';

export const SubscriptionAddons: FC = () => {
  const { t } = useTranslation();
  const { isManageButtonVisible, isManageButtonEnabled, products } =
    useManageSubscriptions();

  return (
    <DefaultCard
      title={t('home:auth.addons.title')}
      sx={styles.getValue('cardContainer')}
      noBorder={false}
      contentSx={styles.getValue('cardContent')}
      button={
        isManageButtonVisible ? (
          <LinkButton
            href={APP_ROUTES.PROFILE.SPECIAL_OFFERS.path}
            variant="contained"
            disabled={!isManageButtonEnabled}
          >
            {t('home:auth.addons.buttons.addNew')}
          </LinkButton>
        ) : undefined
      }
    >
      <SubscriptionAddonsContent products={products} />
    </DefaultCard>
  );
};

const styles = new SxStyles({
  cardContainer: {
    maxHeight: {
      md: '600px',
    },
  },
  cardContent: {
    overflowY: {
      md: 'auto',
    },
  },
  action: {
    color: 'light.main',
  },
});
