import { ChangeEvent, MouseEvent, useState } from 'react';

import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import Box from '@mui/material/Box';
import MaterialTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import { TableBody } from './table-body/TableBody';
import { ARIA_TABLE_ID, ROWS_PER_PAGE_OPTIONS } from './constants';
import { TableHead } from './TableHead';
import { TableToolbar } from './TableToolbar';
import { TableFilteredHeadCell, TableHeadCell, TableOrder } from './types';

interface Props<T> {
  rows: T[];
  headCells: TableHeadCell<T>[];
  orderBy: keyof T;
  order: TableOrder;
  count?: number;
  tableName?: string;
  isLoading?: boolean;
  rowsPerPage?: number[];
  disablePagination?: boolean;
  onChangePage?: (newPage: number, limit: number) => void;
  onChangeRowsPerPage?: (newLimit: number) => void;
}

export const Table = <T,>({
  rows,
  tableName,
  isLoading = false,
  headCells,
  order: initialOrder,
  orderBy: initialOrderBy,
  rowsPerPage: initialRowsPerPage,
  disablePagination = false,
  count,
  onChangePage,
  onChangeRowsPerPage,
}: Props<T>): JSX.Element => {
  const { tenant } = useTenantProps();
  const [order, setOrder] = useState<TableOrder>(initialOrder);
  const [orderBy, setOrderBy] =
    useState<TableHeadCell<T>['id']>(initialOrderBy);

  // Current filtering functionality allows to filter one column at a time
  const [filterBy, setFilterBy] = useState<TableFilteredHeadCell<T>>({
    headCell: headCells[0],
    filterValue: '',
  });
  const filteredRows = getFilteredRows();
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = initialRowsPerPage ?? ROWS_PER_PAGE_OPTIONS;
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const borderColor =
    tenant.theme.specifics?.authTicketTable?.tableBodyCellBorderColor ??
    '#DCDCDC';
  return (
    <Box sx={styles.getValue('root')}>
      {tableName && <TableToolbar tableName={tableName} />}
      <TableContainer>
        <MaterialTable
          sx={styles.getValue('table')}
          aria-labelledby={ARIA_TABLE_ID}
          size="medium"
        >
          <TableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            filterBy={filterBy}
            onFilterChange={handleChangeFilterBy}
          />
          <TableBody
            serverSidePaginationOn={!!onChangePage}
            rows={filteredRows}
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
          />
        </MaterialTable>
      </TableContainer>
      {!disablePagination && (
        <TablePagination
          sx={{
            borderRadius: '16px',
            border: `1px solid ${borderColor}`,
            mt: 2,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count ?? filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );

  function handleRequestSort(
    event: MouseEvent<unknown>,
    property: TableHeadCell<T>['id'],
  ): void {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(event: unknown, newPage: number): void {
    setPage(newPage);
    onChangePage && onChangePage(newPage + 1, rowsPerPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>): void {
    setRowsPerPage(parseInt(event.target.value, 10));
    onChangeRowsPerPage &&
      onChangeRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeFilterBy(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    headCell: TableHeadCell<T>,
  ): void {
    setFilterBy?.({
      headCell: headCell,
      filterValue: event.target.value,
    });
    // reset pagination in case current page is out of range of filtered rows
    setPage(0);
  }

  function getFilteredRows(): T[] {
    if (filterBy?.filterValue && filterBy?.headCell.filterFn) {
      return rows.filter((row) =>
        filterBy.headCell.filterFn?.(row, filterBy.filterValue),
      );
    }
    return rows;
  }
};

const styles = new SxStyles({
  root: {
    width: '100%',
  },
  container: {
    width: '100%',
    mb: 2,
  },
  table: {
    minWidth: 750,
  },
});
