import { FC, RefObject, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';

import { Subscription } from '@api/ocb-digital/subscription/types';
import { COOKIE_STORAGE } from '@lib/storage/constants';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Specifics } from '@lib/tenants/types';
import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { SubscriptionOption } from '@templates/home/home-auth/user-card/SubscriptionOption';
import { Option } from '@ui/dropdown/types';
interface Props {
  firstName?: string;
  lastName?: string;
  companyName?: string;
}

export const UserCardTemplate: FC<Props> = ({
  firstName,
  lastName,
  companyName,
}) => {
  const { t } = useTranslation();
  const isDownSmBreakpoint = useMediaQuery(({ breakpoints }: Theme) =>
    breakpoints.down('sm'),
  );
  const customerNameWrapperRef = useRef<HTMLDivElement>(null);

  const userRole = useMemo(() => Cookies.get(COOKIE_STORAGE.ROLE) ?? '', []);

  const { selectedSubscription, subscriptions, selectSubscription } =
    useSubscriptions();

  const theme = useTheme();

  const options = useMemo<Option[]>(
    () => subscriptions.map(mapOptions),
    [subscriptions],
  );
  const { tenant } = useTenantProps();
  const specificColors = tenant.theme?.specifics;
  const anchorRef = useRef<HTMLElement>(null);
  const dynamicStyles = styles(
    theme,
    anchorRef,
    specificColors,
    isDownSmBreakpoint,
    customerNameWrapperRef,
  );
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (option: Option) => {
    if (option.value) {
      selectSubscription(option.value);
    }
    setOpen(false);
  };

  if (userRole !== 'ROLE_SUBSCRIPTION_USER') {
    return (
      <>
        <Box
          sx={dynamicStyles.getValue('root')}
          bgcolor={
            specificColors?.userCard?.backgroundColor ??
            SHARED_PALETTE.darkestGrey
          }
          ref={anchorRef}
          onClick={handleClick}
        >
          {!isDownSmBreakpoint && (
            <PersonOutlineIcon sx={dynamicStyles.getValue('icon')} />
          )}
          <Box
            ref={customerNameWrapperRef}
            sx={dynamicStyles.getValue('customerNameWrapper')}
          >
            <Typography
              sx={dynamicStyles.getValue('customerName')}
              variant="h6"
              component="div"
            >
              {firstName
                ? t('home:auth.userCard.welcome', {
                    firstName,
                    lastName,
                  })
                : t('home:auth.userCard.companyWelcome', {
                    companyName,
                  })}
            </Typography>
            <Typography sx={dynamicStyles.getValue('identityNumber')}>
              {selectedSubscription?.identifier}
            </Typography>
          </Box>
          {open ? (
            <ExpandLess sx={dynamicStyles.getValue('expandIcon')} />
          ) : (
            <ExpandMore sx={dynamicStyles.getValue('expandIcon')} />
          )}
        </Box>
        <Menu
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          sx={dynamicStyles.getValue('menu')}
        >
          {options.map((option) => (
            <MenuItem
              disabled={option.disabled}
              key={option.value}
              selected={isSelected(option)}
              onClick={() => handleClose(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return (
    <Box
      sx={dynamicStyles.getValue('root')}
      bgcolor={theme.palette.primary.main ?? SHARED_PALETTE.darkestGrey}
    >
      {!isDownSmBreakpoint && (
        <PersonOutlineIcon sx={dynamicStyles.getValue('icon')} />
      )}
      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
        pl={2}
        spacing={1}
        ref={customerNameWrapperRef}
        sx={dynamicStyles.getValue('customerNameWrapper')}
      >
        <Typography
          sx={dynamicStyles.getValue('customerName')}
          variant="h6"
          component="div"
        >
          {firstName
            ? t('home:auth.userCard.welcome', {
                firstName,
                lastName,
              })
            : t('home:auth.userCard.companyWelcome', {
                companyName,
              })}
        </Typography>
        <Typography sx={dynamicStyles.getValue('identityNumber')}>
          {selectedSubscription?.identifier}
        </Typography>
      </Stack>
    </Box>
  );

  function isSelected(option: Option): boolean {
    return option.value === selectedSubscription?.id;
  }
};

function mapOptions({ identifier, id, status }: Subscription): Option {
  return {
    label: <SubscriptionOption label={identifier} status={status} />,
    value: id,
  };
}

const styles = (
  theme: Theme,
  menuAnchor: RefObject<HTMLElement>,
  specificColor?: Specifics,
  isDownSmBreakpoint?: boolean,
  customerNameWrapperRef?: RefObject<HTMLDivElement>,
) =>
  new SxStyles({
    root: {
      p: 2,
      display: isDownSmBreakpoint ? 'block' : 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '16px',
      border: `3px solid ${
        specificColor?.userCard?.borderColor || theme.palette.primary.light
      }`,
      position: 'relative',
    },
    menu: {
      marginTop: 2,
      '& .MuiPaper-root': {
        borderRadius: '16px',
        width: 'auto',
      },
    },
    icon: {
      color: 'light.main',
      fontSize: 80,
    },
    customerNameWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    customerName: {
      color: 'light.main',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: customerNameWrapperRef?.current?.offsetWidth,
    },
    identityNumber: {
      color: 'light.main',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: customerNameWrapperRef?.current?.offsetWidth,
    },
    personalDetailsLink: {
      color: 'light.main',
    },
    spendingReportLink: {
      color: 'secondary.light',
    },
    expandIcon: {
      color: 'light.main',
      position: 'absolute',
      right: 16,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  });
