import { isFuture, isPast, parseISO } from 'date-fns';

import {
  SubscriptionProduct,
  SubscriptionProductChild,
  SubscriptionProductStatus,
  SubscriptionStatus,
} from '@api/ocb-digital/subscription/types';

export const isProductNotExpired = (
  product: SubscriptionProductChild,
): boolean =>
  (new Date(product.validFrom) <= new Date() ||
    productActivatesInFuture(product)) &&
  (product?.validTill ? new Date(product.validTill) > new Date() : true);

export const productActivatesInFuture = (
  product: SubscriptionProductChild,
): boolean => !!product?.validFrom && isFuture(new Date(product.validFrom));

export const filterExpired = ({
  validTill = '',
}: {
  validTill?: SubscriptionProduct['validTill'];
}) => {
  const parsedDate = parseISO(validTill);
  if (!parsedDate) return true;
  return !isPast(parsedDate);
};

export const isStatusActivePendingOrSuspended = (
  status?: SubscriptionProductStatus | SubscriptionStatus,
): boolean =>
  status === 'ACTIVE' || status === 'PENDING' || status === 'SUSPENDED';
