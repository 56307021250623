import { TFunction } from 'next-i18next';

import { SubscriptionProductChild } from '@api/ocb-digital/subscription/types';
import { OfferCardLine } from '@templates/common/offer-card/types';
import { OneTimeFee } from '@ui/price/OneTimeFee';
import { RecurringFee } from '@ui/price/RecurringFee';

export const mapToOfferCardLines = (
  product: SubscriptionProductChild | undefined,
  isBasePackage: boolean,
  t: TFunction,
  formatDateTimeTz: (dateTimeISO: string | number | Date) => string,
): OfferCardLine[] => {
  const lines: OfferCardLine[] = [];

  if (!product) {
    return lines;
  }

  // Base package uses contract start/end dates, add-ons valid from/till
  if (isBasePackage) {
    if (product.contractStartDate) {
      lines.push({
        value: formatDateTimeTz(product.contractStartDate),
        label: t('home:auth.contractStartDate'),
      });
    }

    if (product.contractEndDate) {
      lines.push({
        value: formatDateTimeTz(product.contractEndDate),
        label: t('home:auth.contractEndDate'),
      });
    }
  } else {
    if (product.validFrom) {
      lines.push({
        value: formatDateTimeTz(product.validFrom),
        label: t('home:auth.validFrom'),
      });
    }

    if (product.validTill) {
      lines.push({
        value: formatDateTimeTz(product.validTill),
        label: t('home:auth.validTo'),
      });
    }
  }

  if (product.productPricing) {
    lines.push(
      {
        value: (
          <OneTimeFee
            pricing={product.productPricing}
            display="orderSummary"
            fontSize="16px"
          />
        ),
        label: t('common:oneTimeFee'),
      },
      {
        value: (
          <RecurringFee
            pricing={product.productPricing}
            display="orderSummary"
            fontSize="16px"
          />
        ),
        label: t('common:recurringFee'),
      },
    );
  }
  if (product.status) {
    lines.push({
      value: product.status,
      label: t('home:auth.addons.status'),
    });
  }

  return lines;
};
