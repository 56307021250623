import React, { FC, ReactNode } from 'react';

import { SxTheme } from '@lib/theme/types';
import { Box, BoxProps } from '@mui/material';

import { DefaultCardHeader } from './default-card-header/DefaultCardHeader';
import { DefaultCardContent } from './DefaultCardContent';
import { DefaultCardFooter } from './DefaultCardFooter';
import { HeaderAction } from './types';

interface Props extends BoxProps {
  children: ReactNode;
  footerContent?: ReactNode;
  titleColor?: string;
  titleNoteColor?: string;
  title?: string;
  titleNote?: string;
  contentBackgroundColor?: string;
  isContentPadded?: boolean;
  contentSx?: SxTheme;
  actions?: HeaderAction[];
  button?: ReactNode;
  noBorder?: boolean;
}

export const DefaultCard: FC<Props> = ({
  title,
  titleColor = 'dark.main',
  titleNote,
  titleNoteColor = 'dark.main',
  contentSx,
  contentBackgroundColor = 'inherit',
  isContentPadded = true,
  children,
  actions,
  footerContent,
  button,
  noBorder = true,
  bgcolor = 'background.paper',
  borderColor = '#E6E6E6',
  sx,
  ...rest
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...(!noBorder && {
          border: `1px solid ${borderColor}`,
          backgroundColor: bgcolor,
        }),
        borderRadius: '16px',
        p: 1,
        ...sx,
      }}
      {...rest}
    >
      {(title || actions || button) && (
        <DefaultCardHeader
          title={title}
          titleColor={titleColor}
          titleNote={titleNote}
          titleNoteColor={titleNoteColor}
          actions={actions}
          button={button}
        />
      )}
      <DefaultCardContent
        isContentPadded={isContentPadded}
        contentBackgroundColor={contentBackgroundColor}
        contentSx={contentSx}
      >
        {children}
      </DefaultCardContent>
      {footerContent && <DefaultCardFooter>{footerContent}</DefaultCardFooter>}
    </Box>
  );
};
