import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import { SubscriptionProductChild } from '@api/ocb-digital/subscription/types';
import { useRequestBalance } from '@lib/balance/useRequestBalance';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { useToggle } from '@lib/useToggle';
import { useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';

import { mapToOfferCardLines } from '../common/mapToOfferCardLines';
import { SubscriptionCard } from '../common/SubscriptionCard';
import { AddonBalance } from './addon-balance/AddonBalanceContent';
import { AddonListItem } from './addont-list-item/AddonListItem';
import { AddonListItemActions } from './addont-list-item/AddonListItemActions';

import { useLocalisedDate } from '@lib/time';

interface Props {
  products: SubscriptionProductChild[] | undefined;
}

export const SubscriptionAddonsContent: FC<Props> = ({ products }) => {
  const { t } = useTranslation();
  const { formatDateTimeTz } = useLocalisedDate();
  const { isLoading, isError, isFetching, selectedSubscription } =
    useSubscriptions();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [selectedAddonProductId, setSelectedAddonProductId] =
    useState<string>('');
  const {
    isToggled: isViewBalanceModalOpen,
    toggleOff: closeViewBalanceModal,
    toggleOn: openViewBalanceModal,
  } = useToggle();
  const { refetch } = useRequestBalance(CACHE_KEYS.addonsBalance);

  const onCloseBalanceModal = useCallback(() => {
    setSelectedAddonProductId('');
    closeViewBalanceModal();
  }, [closeViewBalanceModal]);

  useEffect(() => {
    onCloseBalanceModal();
  }, [onCloseBalanceModal, selectedSubscription]);

  if (isFetching || isLoading) {
    return <CenteredLoader />;
  }

  if (isError) {
    return <ErrorPlaceholder />;
  }

  if (!products?.length) {
    return <NoDataPlaceholder title={t('home:auth.addons.noActive')} />;
  }

  return (
    <Stack spacing={4}>
      {products.map((product, index) => {
        const lines = mapToOfferCardLines(product, false, t, formatDateTimeTz);

        return (
          <>
            {isLargeScreen ? (
              <AddonListItem
                product={product}
                lines={lines}
                actions={
                  products.length && (
                    <AddonListItemActions
                      sx={{ marginLeft: 'auto', mr: 2 }}
                      onOpenBalanceModal={() =>
                        onOpenBalanceModal(product.productId)
                      }
                    />
                  )
                }
              />
            ) : (
              <SubscriptionCard
                key={index}
                product={product}
                isBasePackage={false}
                actions={
                  products.length && (
                    <AddonListItemActions
                      onOpenBalanceModal={() =>
                        onOpenBalanceModal(product.productId)
                      }
                    />
                  )
                }
              />
            )}
          </>
        );
      })}
      <ContentModal
        titleLabel={t('home:auth.addons.balanceModal.title')}
        onCancel={onCloseBalanceModal}
        isOpen={isViewBalanceModalOpen}
        showCloseModalIcon
      >
        <AddonBalance productId={selectedAddonProductId} />
      </ContentModal>
    </Stack>
  );

  function onOpenBalanceModal(productId: string) {
    setSelectedAddonProductId(productId);
    refetch();
    openViewBalanceModal();
  }
};
